import apiClient from '../apiClient';

// Функция для валидации SMS кода
export const validateSMS = async (phone, code, telegramId) => {
    try {
        const response = await apiClient.post('/user/validate-tlg', {
            // phone,
            // code,
            telegramId
        });
        return response.data;
    } catch (error) {
        console.error('Error validating SMS:', error);
        throw error;
    }
};
