import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import Loader from "../../../components/Loader";

import Header from "../../../components/Header";
import {AutoIcon, LocationIcon, LogoIcon, RightArrowIcon, UserIcon} from "../../../assets/svg/icons";
import {useSingleData} from "../../../context/SingleContext";
import topImage from "../../../assets/img/main/top2.png";

function RegistrationsForServicesSingle() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const { selectedSingleData, setSelectedSingleData } = useSingleData(); // Достаем функцию для установки выбранного заказа

    const navigate = useNavigate();

    const [isOpenAssets,setIsOpenAssets] = useState(false);
    const [loading,setLoading] = useState(true);

    useEffect(()=>{
        console.log(selectedSingleData, 'selectedSingleData')
        setTimeout(()=>{
            setLoading(false);
        }, 1000)
    }, [selectedSingleData])


    function formatDateToRussian(dateStr) {
        // Преобразуем строку в объект Date
        const dateObj = new Date(dateStr);

        // Массив названий месяцев на русском
        const months = [
            "января", "февраля", "марта", "апреля", "мая", "июня",
            "июля", "августа", "сентября", "октября", "ноября", "декабря"
        ];

        // Получаем день, месяц и год
        const day = dateObj.getDate();
        const month = months[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        // Форматируем строку
        return `${day} ${month} ${year} г.`;
    }


    function getDayOfWeekInRussian(dateStr) {
        // Преобразуем строку в объект Date
        const dateObj = new Date(dateStr);

        // Массив названий дней недели на русском
        const daysOfWeek = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];

        // Получаем название дня недели
        return daysOfWeek[dateObj.getDay()];
    }

    const handleNavigation = () => {
        const address = encodeURIComponent(selectedSingleData?.address);
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

        // Попытка открыть Яндекс.Навигатор
        const yandexNavigatorUrl = `yandexnavi://build_route_on_map?end_text=${address}`;
        const testYandexNavigator = () => {
            // Создаем ссылку и проверяем поддержку
            const a = document.createElement('a');
            a.href = yandexNavigatorUrl;
            a.style.display = 'none';
            document.body.appendChild(a);

            const event = new MouseEvent('click', { bubbles: true, cancelable: true });
            a.dispatchEvent(event);

            setTimeout(() => {
                document.body.removeChild(a);

                // Если Яндекс.Навигатор не открылся, открыть Apple Maps или Google Maps
                const fallbackUrl = isIOS
                    ? `maps://maps.apple.com/?daddr=${address}` // Apple Maps для iOS
                    : `https://www.google.com/maps/search/?api=1&query=${address}`; // Google Maps для других
                window.open(fallbackUrl, '_blank');
            }, 500);
        };

        // Проверяем, поддерживается ли Яндекс.Навигатор
        testYandexNavigator();
    };


    if (loading) {
        return (
            <Loader/>
        )
    }

    return (
        <div className="wrapper wrapper-flex">

            <Header/>

            <Link to={'/'} className="top-block-logo">
                <LogoIcon/>
            </Link>

            <div className={'list-wrapper'} style={{flex:1, marginTop:70, overflow:'scroll'}}>

                <Link to={'/registrations-for-service-single?id=1'} className={'list-item'} style={{background: 'unset', padding: 0}}>
                    <div className={'list-item-center'}>
                        <span style={{fontSize:20, fontWeight: 700}}>Шиномонтаж</span>
                    </div>
                    <div className={'list-item-bottom'}>
                        <span>{formatDateToRussian(selectedSingleData?.date?.date)} {getDayOfWeekInRussian(selectedSingleData?.date?.date)}</span>
                        <span className={'yellow-time'}>{selectedSingleData?.date?.time}</span>
                    </div>

                </Link>


                <h1 className={'reg-for-services-title'}>Адрес центра</h1>

                <div className={'reg-for-services-address'}>
                    {selectedSingleData?.shopName}
                </div>

                <button onClick={handleNavigation} className={'build-a-route'}>
                    Построить маршрут
                </button>

                <h1 className={'reg-for-services-title'}>Гос. номер:</h1>
                <p className={'reg-for-services-val'} >{selectedSingleData?.gtd}</p>


                <h1 className={'reg-for-services-title'}>Автомобиль:</h1>
                <p className={'reg-for-services-val'} >{selectedSingleData?.carName}</p>

                <h1 className={'reg-for-services-title'}>№ партии хранения:</h1>

                <p className={'reg-for-services-val'} >{selectedSingleData?.ox.length > 0 ? selectedSingleData?.ox[0] : 'Не указан'}</p>


            </div>

            <div className={'bottom-btns-wrapper'} style={{padding:0, paddingBottom:20}}>
                <button
                    onClick={() => {
                        navigate(-1);
                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>
                <button
                    onClick={() => {
                    }}
                    className={'red-btn'}
                >
                    Изменить/Отменить
                </button>
            </div>


        </div>
    );
}

export default RegistrationsForServicesSingle;
