import {Sheet} from "react-modal-sheet";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAutoData} from "../../../../hooks/useAutoData";
import {useGuarantees} from "../../../../hooks/useGuarantees";
import {BlockedPhoneIcon, Requiredicon} from "../../../../assets/svg/icons";
import InputMask from "react-input-mask";
import Dropdown from "../../../../components/Dropdown";


const ExtendedWarrantyActivateForm = ({isOpen, setOpen}) => {

    const navigate = useNavigate();
    const ref = useRef();

    const {
        marksLoading,
        marksData,
        marksError,
        fetchAutoMarks,

        modelsLoading,
        modelsData,
        modelsError,
        fetchAutoModels,

        yearsLoading,
        yearsData,
        yearsError,
        fetchAutoYears,
    } = useAutoData();

    const {
        activateUserGuarantee,
        loadingActivation,
        activationResult,
        errorActivation
    } = useGuarantees();

    const [height, setHeight] = useState(window.innerHeight - 100);


    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [gosNumber, setGosNumber] = useState('');

    const [selectedMark, setSelectedMark] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);

    const [isFormValid, setIsFormValid] = useState(false);
    const [isPhoneValid, setIsPhoneValid] = useState(false);

    // Проверка валидности номера телефона
    useEffect(() => {
        console.log(height, 'height')
    }, []);

    // Проверка валидности номера телефона
    useEffect(() => {
        const phoneRegex = /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/;
        setIsPhoneValid(phoneRegex.test(phone));
    }, [phone]);

    useEffect(() => {
        if (errorActivation) {
            console.log(errorActivation, 'errorActivation')
            alert(errorActivation?.errorMessage)
        }
    }, [errorActivation]);

    useEffect(() => {
        if (activationResult) {
            navigate('/extended-warranty-list')
            console.log(errorActivation, 'errorActivation')

        }
    }, [activationResult]);

    // Проверка на заполненность всех полей
    useEffect(() => {
        const isFormComplete = name && surname && email && isPhoneValid && code && gosNumber && selectedMark && selectedModel && selectedYear;
        setIsFormValid(isFormComplete);
    }, [name, surname, email, isPhoneValid, code, gosNumber, selectedMark, selectedModel, selectedYear]);

    const handleActivate = () => {
        if (!isFormValid) {
            return false;
        }
        // Формируем данные для отправки в API
        const guaranteeData = {
            name,
            lastName: surname,
            email,
            phone,
            mark: selectedMark.label,
            model: selectedModel.label,
            year: selectedYear.label,
            registrationNumber: gosNumber,
            agree: true,
            code
        };

        // Вызываем функцию активации гарантии
        activateUserGuarantee(guaranteeData);
    };

    const snapTo = (i) => ref.current?.snapTo(i);

    return (
        <Sheet
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            initialSnap={0}
            snapPoints={[height, 400, 200, 0]}
        >
            <Sheet.Container>

                <Sheet.Header />
                <Sheet.Content>

                    {/*<div style={{width:'100%', height: '100%', background: 'red', overflow: 'scroll'}}>*/}

                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}

                    {/*</div>*/}

                    <div className={'extended-bottomshet-wrapper'}>

                        <div className={'extended-bottomshet-container'}>

                                    <h1 className={'extended-bottomshet-main-title'}>Активировать гарантию</h1>

                                    <div className={'extended-input-wrapper'}>
                                        <input
                                            id={'code'}
                                            className={'input'}
                                            placeholder={'Код активации'}
                                            type="text"
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </div>

                                    <p className={'extended-subtitle'}>Личная информация</p>


                                    <div className={'extended-input-wrapper'}>
                                        <input
                                            id={'name'}
                                            className={'input'}
                                            placeholder={'Имя'}
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>

                                    <div className={'extended-input-wrapper'}>
                                        <input
                                            id={'surname'}
                                            className={'input'}
                                            placeholder={'Фамилия'}
                                            type="text"
                                            value={surname}
                                            onChange={(e) => setSurname(e.target.value)}
                                        />
                                    </div>

                                    <div className={'extended-input-wrapper'}>
                                        <input
                                            id={'email'}
                                            className={'input'}
                                            placeholder={'Email'}
                                            type="text"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>


                                    <div className={'extended-input-wrapper'} style={{opacity: 0.5}}>
                                        <InputMask
                                            mask="+7(999)999-99-99"
                                            value={phone}
                                            onChange={(e) => {
                                                // console.log(e.target.value, 'e.target.value');
                                                setPhone(e.target.value);
                                            }}
                                        >
                                            {(inputProps) => (
                                                <input
                                                    {...inputProps}
                                                    id={'phone'}
                                                    className={'input'}
                                                    placeholder={'Телефон'}
                                                    type="text"
                                                />
                                            )}
                                        </InputMask>
                                        <BlockedPhoneIcon className={'extended-phone-icon'}/>
                                        {/*{!isPhoneValid && phone && <p className="error-text">Неверный формат номера телефона</p>}*/}
                                    </div>


                                    <p className={'extended-subtitle'}>Автомобиль</p>

                                    <Dropdown
                                        isActive={marksData ? true : false}
                                        defaultText={'Укажите марку авто'}
                                        label={<div>Марка <Requiredicon /></div>}
                                        options={marksData || []}
                                        onOptionSelect={(selectedVal) => {
                                            if (selectedVal) {
                                                fetchAutoModels(selectedVal?.value);
                                                setSelectedModel(null);
                                                setSelectedYear(null);
                                            }
                                            setSelectedMark(selectedVal);
                                        }}
                                    />

                                    <Dropdown
                                        isActive={modelsData ? true : false}
                                        defaultText={'Укажите модель авто'}
                                        label={<div>Модель <Requiredicon /></div>}
                                        options={modelsData || []}
                                        onOptionSelect={(selectedVal) => {
                                            if (selectedVal) {
                                                fetchAutoYears(selectedVal?.value, selectedMark?.value);
                                            }
                                            setSelectedYear(null);
                                            setSelectedModel(selectedVal);
                                        }}
                                    />

                                    <Dropdown
                                        isActive={yearsData ? true : false}
                                        defaultText={'Укажите год выпуска'}
                                        label={<div>Год выпуска <Requiredicon /></div>}
                                        options={yearsData || []}
                                        onOptionSelect={(selectedVal) => {
                                            setSelectedYear(selectedVal);
                                        }}
                                    />

                                    <div className={'input-wrapper'}>
                                        <label htmlFor="gosNumber">Гос. номер <Requiredicon /></label>
                                        <input
                                            id={'gosNumber'}
                                            className={'input'}
                                            placeholder={'Введите гос. номер'}
                                            type="text"
                                            value={gosNumber}
                                            onChange={(e) => setGosNumber(e.target.value)}
                                        />
                                    </div>



                        </div>


                        <div className={'bottom-btns-wrapper'} style={{padding: 15, paddingBottom: 15}}>
                            <button
                                style={{flex: 1}}
                                onClick={() => {
                                    setOpen(false)
                                    setTimeout(() => {
                                        setHeight(window.innerHeight - 100)
                                    }, 1000)
                                }}
                                className={'simple-btn'}
                            >
                                Закрыть
                            </button>

                            <button
                                style={{flex: 1, opacity: isFormValid && !loadingActivation ? 1 : 0.5}}
                                onClick={() => {
                                    handleActivate()
                                }}
                                className={'red-btn'}
                            >
                                Отправить
                            </button>
                        </div>
                    </div>

                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop/>
        </Sheet>
    )
}

export default ExtendedWarrantyActivateForm
