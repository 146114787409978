import {Sheet} from "react-modal-sheet";
import React, {useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";


const DataNotEditableBottomSheet = ({isOpen, setOpen}) => {
    const navigate = useNavigate();
    const ref = useRef();

    const [selectedReason, setSelectedReason] = useState(null)
    const [height, setHeight] = useState(220);

    const [cancelSuccess, setCancelSuccess] = useState(false)

    const [reasonsList, setReasonsList] = useState([
        {id: 1, title: 'Не устроили сроки'},
        {id: 2, title: 'Уже купил(а) у вас'},
        {id: 3, title: 'Купил(а) в другой компании'},
        {id: 4, title: 'Ошибка с выбором'},
        {id: 5, title: 'Проблемы с кредитом/рассрочкой'},
        {id: 6, title: 'Не устроила цена'},
        {id: 7, title: 'Передумал покупать'},
    ]);


    const snapTo = (i) => ref.current?.snapTo(i);

    return (
        <Sheet
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            initialSnap={0}
            snapPoints={[height, 200, 400, 0]}
        >
            <Sheet.Container>

                <Sheet.Header />
                <Sheet.Content>

                    <div className={'cancel-order-bottomshet-wrapper'}>

                        <div className={'cancel-success-wrapper'}>
                            <Link to={'https://t.me/kolesoMiniappBot'} className={'cancel-success-message'} style={{background: "#FCE9E8", textDecoration: 'none'}}>
                                Вы пока не можете самостоятельно изменить данные, обратитесь, пожалуйста, в чат поддержки.
                            </Link>
                            <div className={'bottom-btns-wrapper'} style={{padding: 0, paddingBottom: 0}}>
                                <button
                                    style={{flex: 1}}
                                    onClick={() => {
                                        setCancelSuccess(false);
                                        setOpen(false)
                                        setTimeout(() => {
                                            setHeight(220)
                                            setSelectedReason(null)
                                        }, 1000)
                                    }}
                                    className={'simple-btn'}
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>

                    </div>

                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop/>
        </Sheet>
    )
}

export default DataNotEditableBottomSheet
