import React, {useEffect, useState} from 'react';

import '../../assets/css/style.css';
import {LogoIcon, RedLogo} from "../../assets/svg/icons";
import {Link} from "react-router-dom"; // Assuming you have a CSS file for styles


function ErrorPage() {

    return (
        <div className="wrapper wrapper-flex" style={{padding: 20}}>

            <RedLogo/>

            <p style={{fontSize:24, marginTop: 20, lineHeight: '38px', fontWeight: 'bold'}}>
                Для входа в личный <br/> кабинет авторизуйтесь <br/>в чат-боте:
            </p>

            <div className="bottom-btns-wrapper" style={{padding: 0, position: 'absolute', bottom: 50, width: 'calc(100% - 40px)'}}>
                <Link style={{textDecoration: 'none'}} className={'red-btn'} to={'https://t.me/kolesoMiniappBot'}>
                    Перейти в чат-бот
                </Link>
            </div>


        </div>
    )
}

export default ErrorPage;
