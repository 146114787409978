import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../assets/css/style.css'; // Assuming you have a CSS file for styles

import Loader from "../../components/Loader";
import useTranslation from "../../hooks/useTranslation";
import Header from "../../components/Header";
import useMyCard from "../../hooks/useMyCard";
import {BottomArrowIcon, CrocodileBeegIcon, CrocodileIcon, Discount2Icon, LogoIcon} from "../../assets/svg/icons";
import topImage from "../../assets/img/main/top2.png";
import FeedbackForm from "../../components/FeedbackForm";

function MyDiscounts() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();
    const {
        discountAnswer, loadingDiscount, errorDiscount, fetchDiscount
    } = useMyCard();

    const [phoneData, setPhoneData] = useState(null);
    const [noData, setNoData] = useState(false);

    useEffect(()=>{
        let phone = localStorage.getItem('phone')
        setPhoneData(phone);

        if (discountAnswer) {
            if (discountAnswer?.data?.cardNum) {
                setNoData(false);
            } else  {
                setNoData(true);
            }
            console.log(discountAnswer, 'discountAnswer')
        }
    }, [discountAnswer])


    if (loadingDiscount) {
        return (
            <Loader/>
        )
    }

    return (

        <div className="wrapper wrapper-flex" style={{padding: 0}}>

            <Header/>

            {noData ?
                <div className="top-block" style={{height: 70}}>
                    <Link to={'/'} className="top-block-logo">
                        <LogoIcon/>
                    </Link>
                </div>
                :

                <div className="top-block" style={{  borderRadius: 0}}>
                    <Link to={'/'} className="top-block-logo">
                        <LogoIcon/>
                    </Link>
                </div>
            }


            <div
                className={'padding-block'}
                style={{
                    flex: 1,
                    borderRadius: "10px 10px 0 0 ",
                    position: 'relative',
                    top: -10,
                    background: "white",
                    overflow: "scroll"
                }}
            >
                <div className={'top-title-wrapper'}>

                    <div className={'top-title-text'} style={{marginLeft: 0}}>
                        Мои скидки
                    </div>

                </div>

                <div className={'list-wrapper'}>


                    {noData ?
                        <>
                            {/*<img style={{width: '100%'}} src={require('../../assets/img/myorders/noorders.png')}*/}
                            {/*     alt=""/>*/}
                            <p className={'my-discount-nodata-subtitle'} style={{marginBottom:40}}>У меня нет дисконтной карты. Что она мне
                                даст?
                            </p>


                            <div className={'my-discount-nodata-wrapper'}>
                                <div className={'my-discount-nodata-left'}>
                                    <p>10% на все услуги</p>
                                    <p>5% на товары</p>
                                    <p>кроме шин и дисков</p>
                                </div>

                                <div className={'my-discount-nodata-right'}>
                                    <img src={require("../../assets/img/my-discount-no-data.png")} alt=""/>
                                </div>

                            </div>

                            <p className={'my-discount-nodata-subtitle'} style={{marginBottom: 17}}>У меня нет дисконтной карты. Что она мне
                                даст?
                            </p>

                            <ul className={'my-discount-nodata-ul'} >
                                <li>Совершите покупку на 10 000 рублей</li>
                                <li>Получите виртуальную карту, привязанную к вашему телефону</li>
                            </ul>


                            <a href={'https://promo.koleso.ru/discount/'} target={'_blank'} className={'build-a-route'}>
                                Подробнее о программе
                            </a>

                        </>
                        :
                        <>
                            <div className={'categories-item categories-item-discount'} style={{ background: "#4E2B96", padding: 20}}>
                                <div className={'categories-item-top'} style={{color: 'white', height: 'auto', marginBottom:0}}>
                                    Моя карта Колесо.ру
                                </div>
                                <p className={'my-doscount-number'}>№ {discountAnswer?.data?.cardNum}</p>
                                <p className={'my-doscount-phone'}>+ {phoneData}</p>

                                <div className={'my-discount-wrapper'}>
                                    <p>{discountAnswer?.data?.serv}% на все услуги</p>
                                    <p>{discountAnswer?.data?.goods}% на все товары</p>
                                    <p>кроме шин и дисков</p>
                                </div>

                                <Discount2Icon className={'my-discount-icon1'}/>
                                <CrocodileBeegIcon className={'my-discount-icon2'}/>
                            </div>
                        </>
                    }


                </div>


                <FeedbackForm title={'Насколько удобен личный кабинет?'}/>
            </div>


            <div className={'bottom-btns-wrapper'}>
                <button
                    style={{flex: 1, marginRight: 0}}
                    onClick={() => {
                        navigate(-1)
                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>

            </div>


        </div>

    );
}

export default MyDiscounts;
