// import React, {createContext, useContext, useEffect, useState} from 'react';
//
// const SingleContext = createContext();
//
// export const SingleProvider = ({ children }) => {
//     const [selectedSingleData, setSelectedSingleData] = useState(null); // Состояние для хранения выбранного заказа
//
//     useEffect(() => {
//         // localStorage.setItem('selectedSingleData', JS)
//     }, [selectedSingleData]);
//     return (
//         <SingleContext.Provider value={{ selectedSingleData, setSelectedSingleData }}>
//             {children}
//         </SingleContext.Provider>
//     );
// };
//
// // Хук для использования контекста
// export const useSingleData = () => useContext(SingleContext);


import React, { createContext, useContext, useEffect, useState } from 'react';

const SingleContext = createContext();

export const SingleProvider = ({ children }) => {
    // Initialize selectedSingleData with data from localStorage if it exists, otherwise null
    const [selectedSingleData, setSelectedSingleData] = useState(() => {
        const savedData = localStorage.getItem('selectedSingleData');
        return savedData ? JSON.parse(savedData) : null;
    });

    useEffect(() => {
        if (selectedSingleData !== null) {
            localStorage.setItem('selectedSingleData', JSON.stringify(selectedSingleData));
        }
    }, [selectedSingleData]);

    return (
        <SingleContext.Provider value={{ selectedSingleData, setSelectedSingleData }}>
            {children}
        </SingleContext.Provider>
    );
};

// Hook to use the context
export const useSingleData = () => useContext(SingleContext);
