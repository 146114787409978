import React, { useEffect, useState } from "react";
import LoaderComponent from "../Loader";
import { LogoIcon } from "../../assets/svg/icons";

const SliderComponent = ({ onClose }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [loading, setLoading] = useState(false);

        const [slides, setSlides] = useState([

        {
            img: require('../../assets/img/splash/splash1.png')
        },

        {
            img: require('../../assets/img/splash/splash2.png')
        },

        {
            img: require('../../assets/img/splash/splash3.png')
        },

        {
            img: require('../../assets/img/splash/splash4.png')
        },

        {
            img: require('../../assets/img/splash/splash5.png')
        },
        {
            img: require('../../assets/img/splash/splash6.png')

        },

    ]);

    const [randomIndex, setRandomIndex] = useState(null);

    useEffect(() => {
        const index = Math.floor(Math.random() * slides.length);
        setRandomIndex(index);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            onClose();
        }, 2000);
    }, [activeSlide]);

    return (
        <div className="slider-wrapper">
            {(loading || !randomIndex) && <LoaderComponent />}


             <div className={'slider-wrapper-container'}>

                 <div className={'slider-wrapper-container'}>
                     <div className={'slider-wrapper-container-bg'}></div>

                     <div className={'slider-wrapper-container-header'}>

                         <LogoIcon width={60} height={60}/>

                         <p>
                             Добро пожаловать <br/> в Колесо.ру!
                         </p>
                     </div>

                     <div className={`slider-pagination-item 'active'}`}>
                         <img src={slides[randomIndex]?.img} alt=""/></div>


                 </div>

             </div>
        </div>
    );
};

export default SliderComponent;
